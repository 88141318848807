import React, {useContext, useEffect} from 'react';
import {AuthContext} from '../Context/AuthContext';
import {useNavigate} from 'react-router-dom';

const Authentication = () => {
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const keywords = urlParams.get('keywords');


    useEffect(() => {
        const fetchData = async () => {
            const getIp = await fetch('https://api.ipify.org?format=json').then(response => response.json())
            if (token && getIp) {
                console.log("token", token)
                try {
                    const verifyResponse = await fetch('https://formation.youschool.fr/external-api/verify-token', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({token, ip: getIp?.ip}),
                    });
                    const result = await verifyResponse.json();

                    console.log("result", result)

                    if (result.isValid) {
                        console.log("token", result.token)
                        setIsAuthenticated(true);
                        localStorage.setItem('session_active', true);
                        localStorage.setItem('user', JSON.stringify(result.user));
                        localStorage.setItem('token', result.token);
                        if (keywords) {
                            navigate('/accueil?keywords=' + keywords);
                        }
                        else {
                            navigate('/accueil');
                        }
                    } else {
                        setIsAuthenticated(false);
                        localStorage.removeItem('session_active');
                        localStorage.removeItem('user');
                        navigate('/access-denied');
                    }
                } catch (err) {
                    console.log(err.message);
                }
            }
        };
        fetchData();
    }, [token]);

    return (
        <>
            <p>Authentication</p>
        </>
    );
};

export default Authentication;