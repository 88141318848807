import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import FranceTravail from './frontFranceTravail';
import Favoris from './Component/Favori';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthContext } from './Context/AuthContext';
import AccessDenied from "./Component/AccessDenied";
import Authentication from "./Component/Authentication";

const queryClient = new QueryClient();

function AppRoutes() {
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyAuth = async () => {
            const sessionActive = localStorage.getItem('session_active') === 'true';
            if (!sessionActive) {
                const user = JSON.parse(localStorage.getItem('user'));
                const isAuth = user !== null;
                setIsAuthenticated(isAuth);
                if (!isAuth && !location.pathname.startsWith('/access-denied') && !location.pathname.startsWith('/authentication')) {
                    navigate('/access-denied', { replace: true });
                }
            } else {
                setIsAuthenticated(true);
            }
        };
        verifyAuth();
    }, [location, setIsAuthenticated, navigate]);

    if (isAuthenticated === null) {
        return <div>Chargement...</div>;
    }

    return (
        <Routes>
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="/authentication" element={<Authentication />} />
            <Route
                path="/*"
                element={
                    isAuthenticated ? (
                        <Routes>
                            <Route path="/accueil" element={<FranceTravail />} />
                            <Route path="/favoris" element={<Favoris />} />
                        </Routes>
                    ) : (
                        <Navigate to="/access-denied" replace />
                    )
                }
            />
        </Routes>
    );
}

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(null);


    return (
        <QueryClientProvider client={queryClient}>
            <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
                <Router>
                    <AppRoutes />
                </Router>
                <ReactQueryDevtools initialIsOpen={false} />
            </AuthContext.Provider>
        </QueryClientProvider>
    );
}

export default App;