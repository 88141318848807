import React, {useEffect, useState} from 'react';
import Offre from './Component/Offre';
import {FaMapMarkerAlt, FaSearch, FaRegStar, FaFilter} from 'react-icons/fa';
import { IoExitOutline } from "react-icons/io5";
import {RxCross1} from "react-icons/rx";
import { IoBriefcaseOutline } from "react-icons/io5";
import * as PropTypes from "prop-types";
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import useFavorites from './Hook/useFavorites';

Link.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
    children: PropTypes.node
};

const FranceTravail = () => {
    //let baseUrl = "http://127.0.0.1:8000";
    const [searchParams, setSearchParams] = useSearchParams();
    const [keywords, setKeywords] = useState([]);
    const [keywordInput, setKeywordInput] = useState('');
    const [niveau, setNiveau] = useState('');
    const [offres, setOffres] = useState([]);
    const [filteredOffres, setFilteredOffres] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [offresPerPage] = useState(20);
    const [totalElements, setTotalElements] = useState(0);
    const [itemsPerPage] = useState(1000);
    const [villes, setVilles] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationInput, setLocationInput] = useState('');
    const [filterclicked, setfilterclicked] = useState(false);
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [dateFilter, setDateFilter] = useState('');
    const [fetchOffresCount, setFetchOffresCount] = useState(0);
    const [typeContrat, setTypeContrat] = useState('');
    const [dureeHebdo, setDureeHebdo] = useState('');
    const [experience, setExperience] = useState('');
    const location = useLocation();
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const { favorites, favorite_isSuccess, favorites_isError, favorites_isLoading } = useFavorites();

    const isFavorite = (offreId) => {
        if (favorite_isSuccess && favorites && Array.isArray(favorites.data)) {
            return favorites.data.some(fav => fav.offreId === offreId);
        }
        return false;
    };

    useEffect(() => {
        if (favorites_isError) {
            console.error('Erreur lors de la récupération des favoris');
        }
    }, [favorites_isError]);



    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const keywordsParam = params.get('keywords');
        if (keywordsParam) {
            setKeywords(keywordsParam.split(','));
        }
    }, [location.search]);


    const hasKeywords = keywords.length > 0 || keywordInput.length > 0;

    const fetchOffres = () => {
        if (keywords.length === 0) {
            setOffres([]);
            setFilteredOffres([]);
            setTotalElements(0);
            setTotalPages(0);
            return;
        }
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage - 1;
        let url = `https://formation.youschool.fr/external-api/jobs?startIndex=${startIndex}&endIndex=${endIndex}`;

        if (keywords.length > 0) {
            url += `&motsCles=${encodeURIComponent(keywords.join(','))}`;
        }

        if (niveau) {
            url += `&niveau=${niveau}`;
        }

        if (selectedLocations.length > 0) {
            const lieux = selectedLocations.map(l => l.code).join(',');
            url += `&departement=${lieux}`;
        }

        if (typeContrat) {
            url += `&typeContrat=${typeContrat}`;
        }

        if (dureeHebdo) {
            url += `&dureeHebdo=${dureeHebdo}`;
        }
        if (experience) {
            url += `&experience=${experience}`;
        }

        if (sortOption) {
            url += `&sort=${sortOption}`;
        }


        if (dateFilter) {
            const today = new Date();
            let filterDateMin = new Date();
            let filterDateMax = new Date();
            switch(dateFilter) {
                case 'today':
                    filterDateMin = today;
                    filterDateMax = new Date(today.getTime() + (24 * 60 * 60 * 1000));
                    break;
                case 'week':
                    filterDateMin.setDate(today.getDate() - 7);
                    filterDateMax = today;
                    break;
                case 'month':
                    filterDateMin.setMonth(today.getMonth() - 1);
                    filterDateMax = today;
                    break;
                case '3months':
                    filterDateMin.setMonth(today.getMonth() - 3);
                    filterDateMax = today;
                    break;
                case '6months':
                    filterDateMin.setMonth(today.getMonth() - 6);
                    filterDateMax = today;
                    break;
                default:
                    break;
            }
            const dateStringMin = filterDateMin.toISOString().split('T')[0] + 'T00:00:00Z';
            const dateStringMax = filterDateMax.toISOString().split('T')[0] + 'T00:00:00Z';
            url += `&minCreationDate=${dateStringMin}&maxCreationDate=${dateStringMax}`;
            console.log("URL avec filtre de date :", url);
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("Offres récupérées :", data);
                if (currentPage === 1) {
                    setOffres(data);
                    setFilteredOffres(data);
                } else {
                    setOffres(prevOffres => [...prevOffres, ...data]);
                    setFilteredOffres(prevFiltered => [...prevFiltered, ...data]);
                }
                const totalCount = data.totalCount || data.length;
                setTotalElements(totalCount);
            })
            .catch(error => console.error('Erreur lors de la récupération des offres :', error));
    };

    useEffect(() => {
        console.log("useEffect déclenché - fetchOffres appelé");
        setCurrentPage(1);
        setOffres([]);
        setFilteredOffres([]);
        fetchOffres();
        setFetchOffresCount(fetchOffresCount + 1);
    }, [dateFilter, keywords, niveau, selectedLocations, typeContrat, dureeHebdo, experience, sortOption]);

    useEffect(() => {
        setfilterclicked(false);
    }, [filterclicked]);

    useEffect(() => {
        if (currentPage > 1) {
            fetchOffres();
        }
    }, [currentPage]);

    useEffect(() => {
        fetch('https://geo.api.gouv.fr/communes')
            .then(response => response.json())
            .then(data => {
                const villes = data.map(ville => ({
                    code: ville.code,
                    codeDepartement: ville.codeDepartement,
                    nom: `${ville.nom} - ${ville.codeDepartement}`
                }));
                setVilles(villes);
            })
            .catch(error => console.error('Erreur lors de la récupération des villes :', error));
    }, []);

    const handleKeywordChange = (e) => {
        setKeywordInput(e.target.value);
    };

    const handleAddKeyword = () => {
        if (keywordInput && !keywords.includes(keywordInput)) {
            const newKeywords = [...keywords, keywordInput];
            setKeywords(newKeywords);
            setKeywordInput('');
            updateURLWithKeywords(newKeywords);
        }
    };

    const updateURLWithKeywords = (newKeywords) => {
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('keywords', newKeywords.join(','));
            return newParams;
        });
    };

    const handleRemoveKeyword = (keyword) => {
        const newKeywords = keywords.filter(k => k !== keyword);
        setKeywords(newKeywords);
        updateURLWithKeywords(newKeywords);
    };

    const handleLocationChange = (e) => {
        const input = e.target.value;
        setLocationInput(input);
        if (input.length > 2) {
            const suggestions = villes.filter(v =>
                v.nom.toLowerCase().includes(input.toLowerCase())
            ).slice(0, 5);
            setLocationSuggestions(suggestions);
        } else {
            setLocationSuggestions([]);
        }
    };

    const handleLocationSelect = (location) => {
        const newLocation = {
            name: location.nom,
            code: location.codeDepartement,
            fullName: `${location.nom} - ${location.codeDepartement}`
        };
        if (!selectedLocations.some(l => l.fullName === newLocation.fullName)) {
            setSelectedLocations(prevLocations => [...prevLocations, newLocation]);
        }
        setLocationInput('');
        setLocationSuggestions([]);
    };

    const handleRemoveLocation = (fullName) => {
        setSelectedLocations(selectedLocations.filter(l => l.fullName !== fullName));
    };

    const typeContrats = [
        { code: "CDI", libelle: "CDI" },
        { code: "CDD", libelle: "CDD"},
        { code: "MIS", libelle: "Intérim" },
        { code: "SAI", libelle: "Saisonnier" },
        { code: "DIN", libelle: "CDI Intérimaire" }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        updateURLWithKeywords(keywords);
        setCurrentPage(1);
        setOffres([]);
        setFilteredOffres([]);
        fetchOffres();
        setfilterclicked(true);
    };

    const getCurrentOffres = () => {
        const indexOfLastOffre = currentPage * offresPerPage;
        const indexOfFirstOffre = indexOfLastOffre - offresPerPage;
        return filteredOffres.slice(indexOfFirstOffre, indexOfLastOffre);
    };

    const paginate = (direction) => {
        if (direction === 'next') {
            setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(filteredOffres.length / offresPerPage)));
        } else if (direction === 'prev') {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        }
    };
    const resetAllFilters = () => {
        setDateFilter('');
        setTypeContrat('');
        setDureeHebdo('');
        setExperience('');
        setSelectedLocations([]);
    };
    const FiltersActive = () => {
        return dateFilter !== '' ||
            typeContrat !== '' ||
            dureeHebdo !== '' ||
            experience !== '' ||
            selectedLocations.length > 0;
    };

    return (
        <div className="flex flex-col md:flex-row bg-slate-100 min-h-screen md:overflow-hidden overflow-y-auto">
            <aside className="md:fixed md:top-0 md:left-0 md:h-screen bg-white p-5 shadow-md w-full md:w-[200px] flex flex-col">
                <img
                    src="/image/YouJobs-logo.png"
                    alt="YouJobs"
                    className="w-full max-w-[200px] h-auto mb-10 self-center"
                    style={{width: '200px', height: 'auto'}}
                />
                <nav className="space-y-4 flex-grow">
                    <Link to="/"
                          className={`flex items-center text-gray-700 hover:text-blue-700 hover:bg-blue-50 p-2 pl-1 transition duration-150 ease-in-out whitespace-nowrap ${
                              location.pathname === '/accueil' ? 'border-l-4 border-l-blue-600 bg-blue-50 text-blue-700' : 'hover:border-l-4 hover:border-l-blue-600'
                          }`}>
                        <IoBriefcaseOutline className="mr-3 text-lg flex-shrink-0"/>
                        <span className="font-semibold">Offres d'emploi</span>
                    </Link>
                    <Link
                        to={`/favoris${location.search}`}
                        className={`flex items-center text-gray-700 hover:text-blue-700 hover:bg-blue-50 p-2 pl-1 transition duration-150 ease-in-out whitespace-nowrap ${
                            location.pathname === '/favoris' ? 'border-l-4 border-l-blue-600 bg-blue-50 text-blue-700' : 'hover:border-l-4 hover:border-l-blue-600'
                        }`}
                    >
                        <FaRegStar className="mr-3 text-lg flex-shrink-0"/>
                        <span className="font-semibold">Favoris ({favorite_isSuccess && favorites && Array.isArray(favorites.data) ? favorites.data.length : 0})</span>
                    </Link>
                </nav>
                    <Link
                        to="https://jobs.youschool.fr/portail-client/accueil"
                        className="flex items-center mt-14 text-gray-700 hover:text-red-500 hover:bg-red-50 p-2 pl-1 transition duration-150 ease-in-out whitespace-nowrap hover:border-l-4 hover:border-l-red-600"
                        rel="noopener noreferrer"
                    >
                        <IoExitOutline className="mr-3 text-lg flex-shrink-0"/>
                        <span className="font-semibold">Déconnexion</span>
                    </Link>
            </aside>
            <main className="flex-1 p-4 md:p-8 md:ml-[200px] md:overflow-y-auto">
                <div className="p-4 md:p-6 mb-8">
                    <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 mb-4">
                        <div className="flex-1 relative">
                            <div className="bg-white relative flex items-center w-full border border-gray-300 rounded-md pl-10 pr-4 h-[50px] overflow-x-auto">
                                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                                <div className="flex items-center space-x-1 w-full flex-nowrap">
                                    {keywords.map(keyword => (
                                        <span key={keyword}
                                              className="flex-shrink-0 bg-blue-100 text-blue-500 px-2 py-1 rounded-md flex items-center text-md font-semibold">
                                        {keyword}
                                            <button onClick={() => handleRemoveKeyword(keyword)}
                                                    className="ml-1 mt-1 text-blue-400 hover:text-red-500">
                                            <RxCross1 size={13} strokeWidth={1}/>
                                        </button>
                                    </span>
                                    ))}
                                    <input
                                        type="text"
                                        placeholder={keywords.length > 0 ? "" : "Ajouter un mot-clé"}
                                        value={keywordInput}
                                        onChange={handleKeywordChange}
                                        onKeyPress={(e) => e.key === 'Enter' && handleAddKeyword()}
                                        className="flex-grow min-w-[200px] h-full py-3 px-4 outline-none border-none bg-transparent focus:outline-none focus:ring-0"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 relative">
                            <div className="w-full h-[50px] py-3 px-4 border border-gray-300 bg-white rounded-md pl-10 flex items-center overflow-x-auto">
                                <FaMapMarkerAlt
                                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                                <div className="flex items-center space-x-1 w-full flex-nowrap">
                                    {selectedLocations.map(location => (
                                        <span key={location.fullName} className="flex-shrink-0 bg-blue-100 text-blue-500 px-2 py-1 rounded-md flex items-center text-sm font-semibold">
                                            {location.name}
                                            <button onClick={() => handleRemoveLocation(location.fullName)} className="ml-1 mt-1 text-blue-400 hover:text-red-500">
                                                <RxCross1 size={13} strokeWidth={1}/>
                                            </button>
                                        </span>
                                    ))}
                                    <input
                                        type="text"
                                        placeholder={selectedLocations.length > 0 ? "" : "Lieu"}
                                        value={locationInput}
                                        onChange={handleLocationChange}
                                        className="flex-grow min-w-[50px] outline-none border-none bg-transparent focus:outline-none focus:ring-0"
                                    />
                                </div>
                            </div>
                            {locationSuggestions.length > 0 && (
                                <div className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg">
                                    {locationSuggestions.map((location, index) => (
                                        <div
                                            key={index}
                                            className="p-2 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => handleLocationSelect(location)}
                                        >
                                            {location.nom}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <button onClick={handleSubmit} className="w-full md:w-auto bg-blue-500 text-white px-4 py-2 rounded-md">
                            Trouver une offre
                        </button>
                    </div>
                    <div className="md:hidden mb-4">
                        <button
                            onClick={() => setShowMobileFilters(!showMobileFilters)}
                            className="w-full bg-gray-200 text-gray-800 px-4 py-2 rounded-md flex items-center justify-center"
                        >
                            <FaFilter className="mr-2" />
                            Filtres
                        </button>
                    </div>
                    {showMobileFilters && (
                        <div className="md:hidden space-y-4 mb-4">
                            <select
                                className="p-2 px-4 border border-gray-300 rounded-md w-full"
                                value={dateFilter}
                                onChange={e => setDateFilter(e.target.value)}
                            >
                                <option value="">Date de création</option>
                                <option value="today">Aujourd'hui</option>
                                <option value="week">Cette semaine</option>
                                <option value="month">Ce mois</option>
                                <option value="3months">3 derniers mois</option>
                                <option value="6months">6 derniers mois</option>
                            </select>
                            <select
                                className="p-2 px-4 border border-gray-300 rounded-md w-full"
                                value={typeContrat}
                                onChange={(e) => setTypeContrat(e.target.value)}
                            >
                                <option value="">Type de contrat</option>
                                {typeContrats.map((contrat) => (
                                    <option key={contrat.code} value={contrat.code}>
                                        {contrat.libelle}
                                    </option>
                                ))}
                            </select>
                            <select
                                className="p-2 px-4 border border-gray-300 rounded-md w-full"
                                value={dureeHebdo}
                                onChange={(e) => setDureeHebdo(e.target.value)}
                            >
                                <option value="">Durée hebdo</option>
                                <option value="1">Temps plein</option>
                                <option value="2">Temps partiel</option>
                                <option value="0">Non renseignée</option>
                            </select>
                            <select
                                className="p-2 px-4 border border-gray-300 rounded-md w-full"
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                            >
                                <option>Expérience</option>
                                <option value={"1"}>Moins de 1 an</option>
                                <option value={"2"}>1 à 3 ans</option>
                                <option value={"3"}>Plus de 3 ans</option>
                                <option value={"O"}>Non renseignée</option>
                            </select>
                            {/* Ajout du bouton "Tout réinitialiser" pour mobile */}
                            <button
                                onClick={resetAllFilters}
                                disabled={!FiltersActive()}
                                className={`w-full p-2 border border-gray-300 text-red-500 text-sm rounded-md font-semibold ${
                                    FiltersActive()
                                        ? 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                                        : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                }`}
                            >
                                Tout réinitialiser
                            </button>
                        </div>
                    )}
                    <div className="hidden md:flex md:flex-row md:space-x-4">
                        <select
                            className="p-2 px-4 border border-gray-300 rounded-md w-full md:w-[17rem]"
                            value={dateFilter}
                            onChange={e => setDateFilter(e.target.value)}
                        >
                            <option value="">Date de création</option>
                            <option value="today">Aujourd'hui</option>
                            <option value="week">Cette semaine</option>
                            <option value="month">Ce mois</option>
                            <option value="3months">3 derniers mois</option>
                            <option value="6months">6 derniers mois</option>
                        </select>
                        <select
                            className="p-2 px-4 border border-gray-300 rounded-md w-full md:w-[17rem]"
                            value={typeContrat}
                            onChange={(e) => setTypeContrat(e.target.value)}
                        >
                            <option value="">Type de contrat</option>
                            {typeContrats.map((contrat) => (
                                <option key={contrat.code} value={contrat.code}>
                                    {contrat.libelle}
                                </option>
                            ))}
                        </select>
                        <select
                            className="p-2 px-4 border border-gray-300 rounded-md w-full md:w-[17rem]"
                            value={dureeHebdo}
                            onChange={(e) => setDureeHebdo(e.target.value)}
                        >
                            <option value="">Durée hebdo</option>
                            <option value="1">Temps plein</option>
                            <option value="2">Temps partiel</option>
                            <option value="0">Non renseignée</option>
                        </select>
                        <select
                            className="p-2 px-4 border border-gray-300 rounded-md w-full md:w-[17rem]"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                        >
                            <option>Expérience</option>
                            <option value={"1"}>Moins de 1 an</option>
                            <option value={"2"}>1 à 3 ans</option>
                            <option value={"3"}>Plus de 3 ans</option>
                            <option value={"O"}>Non renseignée</option>
                        </select>
                    </div>
                </div>
                {hasKeywords ? (
                    <>
                        <div className="text-xl mb-4 flex flex-col md:flex-row justify-between items-start md:items-center">
                            <div className="mb-2 md:mb-0">
                                <span className="font-bold">{totalElements} offres</span>
                                <span className="font-normal"> pour {keywords.join(', ')}</span>
                            </div>
                            <div
                                className="text-slate-500 flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                                <select
                                    className="p-2 border border-gray-300 rounded-md w-full md:w-auto"
                                    value={sortOption}
                                    onChange={(e) => {
                                        setSortOption(e.target.value);
                                    }}
                                >
                                    <option value="0">Trier par pertinence</option>
                                    <option value="1">Trier par date</option>
                                </select>
                                <button
                                    onClick={resetAllFilters}
                                    disabled={!FiltersActive()}
                                    className={`hidden md:block w-full md:w-auto p-2 border border-gray-300 text-red-500 text-sm rounded-md font-semibold ${
                                        FiltersActive()
                                            ? 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                                            : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                    }`}
                                >
                                    Tout réinitialiser
                                </button>
                            </div>
                        </div>
                        <div className="space-y-4">
                            {getCurrentOffres().map((offre) => (
                                offre && (
                                    <Offre
                                        key={offre.id}
                                        offre={offre}
                                        toggleFavorite={() => (offre)}
                                        isFavorite={isFavorite(offre.id)}
                                    />
                                )
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="text-center py-10">
                        <h2 className="text-2xl font-bold mb-4">Commencez votre recherche</h2>
                        <p className="text-gray-600">
                            Saisissez un métier, une compétence ou un mot-clé puis indiquez le lieu désiré dans les
                            champs ci-dessus.
                        </p>
                    </div>
                )}
                {hasKeywords && filteredOffres.length > offresPerPage && (
                    <div className="mt-8 flex justify-center">
                        <nav className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:-space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <button
                                onClick={() => paginate('prev')}
                                disabled={currentPage === 1}
                                className={`relative flex items-center justify-center px-4 py-2 text-sm font-semibold ${
                                    currentPage === 1
                                        ? 'text-gray-300 cursor-not-allowed'
                                        : 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                } md:rounded-l-md`}
                            >
                                Page précédente
                            </button>
                            <button
                                onClick={() => paginate('next')}
                                disabled={currentPage === Math.ceil(filteredOffres.length / offresPerPage)}
                                className={`relative flex items-center justify-center px-4 py-2 text-sm font-semibold ${
                                    currentPage === Math.ceil(filteredOffres.length / offresPerPage)
                                        ? 'text-gray-300 cursor-not-allowed'
                                        : 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                } md:rounded-r-md`}
                            >
                                Page suivante
                            </button>
                        </nav>
                    </div>
                )}
            </main>
        </div>
    );
}
export default FranceTravail;