import {useQuery} from "@tanstack/react-query";

const useFavorites = (userId) => {

    const {data :  favorites, isSuccess : favorite_isSuccess, isError : favorites_isError, isLoading : favorites_isLoading } = useQuery({
        queryKey: ['favorites', userId],
        queryFn: () => fetch(`https://formation.youschool.fr/external-api/get-Favorites`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json()),
        staleTime: Infinity,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    return {favorites, favorite_isSuccess, favorites_isError, favorites_isLoading};

};

export default useFavorites;
